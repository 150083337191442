import React, { useState } from 'react';
import { ShoppingCart, UtensilsCrossed, MapPin, Phone, CreditCard, MessageSquare } from 'lucide-react';
import { MenuItem, CartItem, OrderDetails } from './types';
import { menuItems } from './data/menu';
import MenuSection from './components/MenuSection';
import Cart from './components/Cart';

function App() {
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [deliveryOption, setDeliveryOption] = useState<'pickup' | 'delivery' | 'sitdown'>('pickup');

  const handleAddToCart = (
    item: MenuItem,
    breadChoice?: 'white' | 'brown',
    addChips?: boolean,
    hotdogSize?: 'single' | 'double',
    toastieType?: 'toastie' | 'sandwich',
    addFeta?: boolean
  ) => {
    setCartItems((prev) => [...prev, { ...item, quantity: 1, breadChoice, addChips, hotdogSize, toastieType, addFeta }]);
  };

  const handleRemoveFromCart = (id: string) => {
    setCartItems((prev) => prev.filter((item) => item.id !== id));
  };

  const handleCustomOrder = () => {
    const whatsappUrl = `https://wa.me/27662661968?text=Hi Ardele | Nono,%0A%0AI would like to inquire about a custom order or event platter.`;
    window.open(whatsappUrl, '_blank');
  };

  const menuSections = Object.entries(
    menuItems.reduce((acc, item) => {
      if (!acc[item.category]) acc[item.category] = [];
      acc[item.category].push(item);
      return acc;
    }, {} as Record<string, MenuItem[]>)
  );

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <header className="bg-gradient-to-r from-red-700 to-red-900 text-white shadow-lg">
        <div className="max-w-4xl mx-auto px-4">
          <div className="py-6">
            <div className="flex justify-between items-center">
              <div>
                <h1 className="text-3xl font-bold">Hades Shop</h1>
                <p className="text-red-100 mt-1">Come and experience our little garden paradise</p>
              </div>
              <button
                onClick={() => setIsCartOpen(true)}
                className="p-3 bg-white/10 rounded-full relative hover:bg-white/20 transition-colors"
              >
                <ShoppingCart className="text-white" size={24} />
                {cartItems.length > 0 && (
                  <span className="absolute -top-1 -right-1 bg-red-500 text-white w-5 h-5 rounded-full flex items-center justify-center text-xs font-bold">
                    {cartItems.length}
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </header>

      <main className="flex-1 max-w-4xl mx-auto px-4 py-8">
        {menuSections.map(([category, items]) => (
          <MenuSection
            key={category}
            title={category}
            items={items}
            onAddToCart={handleAddToCart}
          />
        ))}

        <div className="bg-gradient-to-r from-red-50 to-red-100 rounded-xl p-6 mb-12 text-center">
          <h2 className="text-2xl font-bold text-red-900 mb-3">Custom Orders & Event Platters</h2>
          <p className="text-red-700 mb-4 max-w-2xl mx-auto">Planning an event or need a custom order? We offer special catering services and custom platters for any occasion. Perfect for corporate events, parties, and special celebrations!</p>
          <button
            onClick={handleCustomOrder}
            className="bg-red-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-red-700 transition-colors inline-flex items-center gap-2"
          >
            <MessageSquare size={20} />
            Inquire on WhatsApp
          </button>
        </div>
      </main>

      <footer className="bg-gray-900 text-white mt-auto">
        <div className="max-w-4xl mx-auto px-4 py-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div className="space-y-6">
              <h3 className="text-xl font-semibold mb-4">Contact & Location</h3>
              <div className="space-y-4">
                <div className="flex items-center gap-3">
                  <MapPin className="text-red-400 flex-shrink-0" />
                  <span>154 Braam Fisher Drive, Randburg</span>
                </div>
                <div className="flex items-center gap-3">
                  <Phone className="text-red-400 flex-shrink-0" />
                  <span>WhatsApp Ardele & Nono - 066 266 1968</span>
                </div>
                <div className="flex items-center gap-3">
                  <CreditCard className="text-red-400 flex-shrink-0" />
                  <div>
                    <p>Card machine available</p>
                    <p className="text-sm text-gray-400">Minimum swipe R25.00</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-6">
              <h3 className="text-xl font-semibold mb-4">About Us</h3>
              <div className="flex items-start gap-3">
                <UtensilsCrossed className="text-red-400 flex-shrink-0 mt-1" />
                <div className="space-y-3">
                  <p>Come and enjoy something to eat and drink while browsing our shop with new and pre-loved goods.</p>
                  <p className="text-sm text-gray-400">The food images are AI generated and for illustration only, actual images may vary.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <Cart
        items={cartItems}
        isOpen={isCartOpen}
        onClose={() => setIsCartOpen(false)}
        onRemove={handleRemoveFromCart}
        onCheckout={(details: OrderDetails) => {
          const orderText = `Hi Ardele | Nono,\n\nI would like to place an order for:\n\n${details.items
            .map((item) => {
              let text = `${item.quantity}x ${item.name}`;
              if (item.breadChoice) text += ` (${item.breadChoice})`;
              if (item.hotdogSize === 'double') text += ` (double)`;
              if (item.toastieType) text += ` (${item.toastieType})`;
              if (item.addChips) text += ' + chips';
              if (item.addFeta) text += ' + feta';
              return text;
            })
            .join('\n')}\n\n${details.deliveryOption.toUpperCase()}\n\nTotal: R${details.total.toFixed(2)}`;

          const whatsappUrl = `https://wa.me/27662661968?text=${encodeURIComponent(orderText)}`;
          window.open(whatsappUrl, '_blank');
        }}
        deliveryOption={deliveryOption}
        setDeliveryOption={setDeliveryOption}
      />
    </div>
  );
}

export default App;