import React, { useState } from 'react';
import { MenuItem } from '../types';
import { X } from 'lucide-react';

interface ItemCustomizeModalProps {
  item: MenuItem;
  onClose: () => void;
  onAddToCart: (
    item: MenuItem,
    breadChoice?: 'white' | 'brown',
    addChips?: boolean,
    hotdogSize?: 'single' | 'double',
    toastieType?: 'toastie' | 'sandwich',
    addFeta?: boolean,
    beverageType?: 'soft-drink-small' | 'soft-drink-large' | 'juice' | 'water' | 'energy' | 'cappuccino' | 'coffee' | 'tea'
  ) => void;
}

export default function ItemCustomizeModal({
  item,
  onClose,
  onAddToCart,
}: ItemCustomizeModalProps) {
  const [breadChoice, setBreadChoice] = useState<'white' | 'brown'>('white');
  const [addChips, setAddChips] = useState(false);
  const [hotdogSize, setHotdogSize] = useState<'single' | 'double'>('single');
  const [toastieType, setToastieType] = useState<'toastie' | 'sandwich'>('toastie');
  const [addFeta, setAddFeta] = useState(false);
  const [waterType, setWaterType] = useState<'still' | 'sparkling'>('still');
  const [beverageType, setBeverageType] = useState<'soft-drink-small' | 'soft-drink-large' | 'juice' | 'water' | 'energy' | 'cappuccino' | 'coffee' | 'tea'>();

  const calculateTotal = () => {
    let total = item.price || 0;
    
    if (item.id === 'special-hotdog' && hotdogSize === 'double') {
      total = 32.00;
    }
    
    if (addChips) {
      total += 20.00;
    }
    
    if (addFeta) {
      total += 8.00;
    }

    return total;
  };

  const handleSubmit = () => {
    onAddToCart(
      {
        ...item,
        price: calculateTotal(),
      },
      breadChoice,
      addChips,
      hotdogSize,
      toastieType,
      addFeta,
      beverageType
    );
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-end sm:items-center justify-center">
      <div className="bg-white w-full sm:w-96 rounded-t-2xl sm:rounded-2xl max-h-[90vh] overflow-y-auto">
        <div className="p-4 border-b sticky top-0 bg-white">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-semibold">{item.name}</h3>
            <button onClick={onClose} className="p-2 -mr-2">
              <X size={20} />
            </button>
          </div>
        </div>

        <div className="p-4 space-y-6">
          {item.options?.beverageType && (
            <div className="space-y-3">
              <label className="block text-sm font-medium text-gray-700">
                Choose your beverage
              </label>
              <div className="space-y-2">
                {item.id === 'beverage-water' && (
                  <div className="grid grid-cols-2 gap-3">
                    <button
                      onClick={() => setWaterType('still')}
                      className={`p-4 rounded-xl border-2 transition-all ${
                        waterType === 'still' ? 'border-red-600 bg-red-50' : 'border-gray-200'
                      }`}
                    >
                      Still
                    </button>
                    <button
                      onClick={() => setWaterType('sparkling')}
                      className={`p-4 rounded-xl border-2 transition-all ${
                        waterType === 'sparkling' ? 'border-red-600 bg-red-50' : 'border-gray-200'
                      }`}
                    >
                      Sparkling
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}

          {item.options?.toastieType && (
            <div className="space-y-3">
              <label className="block text-sm font-medium text-gray-700">
                Choose style
              </label>
              <div className="grid grid-cols-2 gap-3">
                {['toastie', 'sandwich'].map((type) => (
                  <button
                    key={type}
                    onClick={() => setToastieType(type as 'toastie' | 'sandwich')}
                    className={`p-4 rounded-xl border-2 transition-all ${
                      toastieType === type
                        ? 'border-red-600 bg-red-50'
                        : 'border-gray-200'
                    }`}
                  >
                    <span className="capitalize">{type}</span>
                  </button>
                ))}
              </div>
            </div>
          )}

          {item.options?.bread && (
            <div className="space-y-3">
              <label className="block text-sm font-medium text-gray-700">
                Choose your bread
              </label>
              <div className="grid grid-cols-2 gap-3">
                {['white', 'brown'].map((type) => (
                  <button
                    key={type}
                    onClick={() => setBreadChoice(type as 'white' | 'brown')}
                    className={`p-4 rounded-xl border-2 transition-all ${
                      breadChoice === type
                        ? 'border-red-600 bg-red-50'
                        : 'border-gray-200'
                    }`}
                  >
                    <span className="capitalize">{type}</span>
                  </button>
                ))}
              </div>
            </div>
          )}

          {item.options?.hotdogSize && (
            <div className="space-y-3">
              <label className="block text-sm font-medium text-gray-700">
                Choose size
              </label>
              <div className="grid grid-cols-2 gap-3">
                <button
                  onClick={() => setHotdogSize('single')}
                  className={`p-4 rounded-xl border-2 transition-all ${
                    hotdogSize === 'single'
                      ? 'border-red-600 bg-red-50'
                      : 'border-gray-200'
                  }`}
                >
                  Single (R25)
                </button>
                <button
                  onClick={() => setHotdogSize('double')}
                  className={`p-4 rounded-xl border-2 transition-all ${
                    hotdogSize === 'double'
                      ? 'border-red-600 bg-red-50'
                      : 'border-gray-200'
                  }`}
                >
                  Double (R32)
                </button>
              </div>
            </div>
          )}

          {item.options?.chips && (
            <div className="space-y-3">
              <label className="block text-sm font-medium text-gray-700">
                Add chips?
              </label>
              <button
                onClick={() => setAddChips(!addChips)}
                className={`w-full p-4 rounded-xl border-2 transition-all ${
                  addChips ? 'border-red-600 bg-red-50' : 'border-gray-200'
                }`}
              >
                Add chips (+R20)
              </button>
            </div>
          )}

          {item.options?.feta && (
            <div className="space-y-3">
              <label className="block text-sm font-medium text-gray-700">
                Add Feta?
              </label>
              <button
                onClick={() => setAddFeta(!addFeta)}
                className={`w-full p-4 rounded-xl border-2 transition-all ${
                  addFeta ? 'border-red-600 bg-red-50' : 'border-gray-200'
                }`}
              >
                Add Feta (+R8)
              </button>
            </div>
          )}

          <div className="pt-4">
            <button
              onClick={handleSubmit}
              className="w-full bg-red-600 text-white py-4 rounded-xl font-semibold hover:bg-red-700 transition-colors"
            >
              {typeof item.price === 'number' ? `Add to Cart - R${calculateTotal().toFixed(2)}` : 'Add to Cart'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}