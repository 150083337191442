import React, { useState } from 'react';
import { MenuItem } from '../types';
import ItemCustomizeModal from './ItemCustomizeModal';

interface MenuSectionProps {
  title: string;
  items: MenuItem[];
  onAddToCart: (
    item: MenuItem,
    breadChoice?: 'white' | 'brown',
    addChips?: boolean,
    hotdogSize?: 'single' | 'double',
    toastieType?: 'toastie' | 'sandwich',
    addFeta?: boolean
  ) => void;
}

export default function MenuSection({ title, items, onAddToCart }: MenuSectionProps) {
  const [selectedItem, setSelectedItem] = useState<MenuItem | null>(null);

  const getItemDescription = (item: MenuItem) => {
    // Only show the item description
    return item.description || '';
  };

  return (
    <div className="mb-12">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">{title}</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {items.map((item) => (
          <div 
            key={item.id} 
            className="bg-white rounded-xl shadow-sm overflow-hidden hover:shadow-md transition-shadow cursor-pointer"
            onClick={() => setSelectedItem(item)}
          >
            <div className="flex h-32">
              <img
                src={item.image}
                alt={item.name}
                className="w-32 h-32 object-cover"
              />
              <div className="p-4 flex-1 flex flex-col">
                <div>
                  <h3 className="font-medium text-gray-800">{item.name}</h3>
                  {typeof item.price === 'number' && (
                    <p className="text-red-600 font-semibold mt-1">
                      R {item.price.toFixed(2)}
                    </p>
                  )}
                  <p className="text-sm text-gray-500 mt-1 whitespace-pre-line">
                    {getItemDescription(item)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {selectedItem && (
        <ItemCustomizeModal
          item={selectedItem}
          onClose={() => setSelectedItem(null)}
          onAddToCart={onAddToCart}
        />
      )}
    </div>
  );
}