export const menuItems = [
  {
    id: 'toasties-bacon-egg',
    category: 'Toasties & Sandwiches',
    name: 'Bacon & Egg',
    price: 48.00,
    image: 'https://client.boilerroom.online/hades/1.bacon%20and%20egg%20toastie.png',
    description: 'Crispy bacon, fresh eggs',
    options: { bread: true, chips: true, toastieType: true }
  },
  {
    id: 'toasties-bacon-cheese',
    category: 'Toasties & Sandwiches',
    name: 'Bacon & Cheese',
    price: 44.00,
    image: 'https://client.boilerroom.online/hades/2.%20bacon%20and%20cheese%20toastie.png',
    description: 'Crispy bacon, melted cheese',
    options: { bread: true, chips: true, toastieType: true }
  },
  {
    id: 'toasties-egg-mayo',
    category: 'Toasties & Sandwiches',
    name: 'Egg Mayo',
    price: 25.00,
    image: 'https://client.boilerroom.online/hades/3.egg%20and%20mayonnaise%20toastie.png',
    description: 'Fresh eggs, creamy mayonnaise',
    options: { bread: true, chips: true, toastieType: true }
  },
  {
    id: 'toasties-cheese-tom',
    category: 'Toasties & Sandwiches',
    name: 'Cheese & Tom',
    price: 28.00,
    image: 'https://client.boilerroom.online/hades/4.%20cheese%20and%20tomato%20toastie.png',
    description: 'Melted cheese, fresh tomato',
    options: { bread: true, chips: true, toastieType: true }
  },
  {
    id: 'toasties-ham-cheese-tom',
    category: 'Toasties & Sandwiches',
    name: 'Ham, Cheese & Tom',
    price: 34.00,
    image: 'https://client.boilerroom.online/hades/5.%20ham%20cheese%20and%20tomato%20toastie.png',
    description: 'Ham, melted cheese, fresh tomato',
    options: { bread: true, chips: true, toastieType: true }
  },
  {
    id: 'toasties-chicken-mayo',
    category: 'Toasties & Sandwiches',
    name: 'Chicken Mayo',
    price: 41.00,
    image: 'https://client.boilerroom.online/hades/6.%20chicken%20and%20mayonnaise%20toastie.png',
    description: 'Tender chicken, creamy mayonnaise',
    options: { bread: true, chips: true, toastieType: true }
  },
  {
    id: 'toasties-cheese-mushroom',
    category: 'Toasties & Sandwiches',
    name: 'Cheese, mushroom, onions & chilli',
    price: 34.00,
    image: 'https://client.boilerroom.online/hades/7.%20cheese%2C%20mushroom%2C%20onion%2C%20and%20green%20chili%20toastie.png',
    description: 'Cheese, mushrooms, onions, green chili',
    options: { bread: true, chips: true, toastieType: true }
  },
  {
    id: 'omelette-cheese-onion',
    category: 'Omelette',
    name: 'Cheese & Onion',
    price: 40.00,
    image: 'https://client.boilerroom.online/hades/8.%20cheese%20and%20onion%20omelette.png',
    description: 'Fluffy eggs, cheese, sautéed onions',
    options: { chips: true }
  },
  {
    id: 'salad-plain',
    category: 'Salads',
    name: 'Plain Salad',
    price: 36.00,
    image: 'https://client.boilerroom.online/hades/9.%20plain%20salad.png',
    description: 'Fresh lettuce, tomato, cucumber, onion',
    options: { feta: true }
  },
  {
    id: 'salad-bacon',
    category: 'Salads',
    name: 'Bacon Salad',
    price: 50.00,
    image: 'https://client.boilerroom.online/hades/10.%20bacon%20salad.png',
    description: 'Fresh salad with crispy bacon strips',
    options: { feta: true }
  },
  {
    id: 'salad-chicken',
    category: 'Salads',
    name: 'Chicken Salad',
    price: 48.00,
    image: 'https://client.boilerroom.online/hades/11.%20chicken%20salad.png',
    description: 'Fresh salad with tender chicken strips',
    options: { feta: true }
  },
  {
    id: 'burger-beef',
    category: 'Burgers',
    name: 'Beef Burger',
    price: 44.00,
    image: 'https://client.boilerroom.online/hades/12.%20beef%20burger.png',
    description: '100% beef patty, lettuce, tomato, onion',
    options: { chips: true }
  },
  {
    id: 'burger-cheese',
    category: 'Burgers',
    name: 'Cheese Burger',
    price: 49.00,
    image: 'https://client.boilerroom.online/hades/13.%20cheese%20burger.png',
    description: '100% beef patty, cheese, lettuce, tomato',
    options: { chips: true }
  },
  {
    id: 'burger-chicken',
    category: 'Burgers',
    name: 'Chicken Burger',
    price: 45.00,
    image: 'https://client.boilerroom.online/hades/14.%20chicken%20burger.png',
    description: 'Crispy chicken fillet, lettuce, tomato',
    options: { chips: true }
  },
  {
    id: 'special-dagwood',
    category: 'Specialities',
    name: 'Dagwood',
    price: 69.00,
    image: 'https://client.boilerroom.online/hades/15.%20dagwood%20sandwich%20specialitie.png',
    description: 'Triple-decker with beef patty, egg, bacon, cheese',
    options: { chips: true }
  },
  {
    id: 'special-hotdog',
    category: 'Specialities',
    name: 'Hotdog',
    price: 25.00,
    image: 'https://client.boilerroom.online/hades/16.%20hotdog%20specialitie.png',
    description: 'Vienna sausage, mustard, tomato sauce',
    options: { hotdogSize: true, chips: true }
  },
  {
    id: 'special-frankie',
    category: 'Specialities',
    name: 'Frankie Special',
    price: 54.00,
    image: 'https://client.boilerroom.online/hades/17.%20frankie%20special%20specialitie.png',
    description: 'Special house sauce, mixed ingredients',
    options: { chips: true }
  },
  {
    id: 'special-zak',
    category: 'Specialities',
    name: 'Zak\'s Special',
    price: 40.00,
    image: 'https://client.boilerroom.online/hades/18.%20Zak%27s%20Special%20specialitie.png',
    description: 'Chef\'s special ingredients',
    options: { chips: true }
  },
  {
    id: 'roll-cheese',
    category: 'Fresh Rolls',
    name: 'Cheese, tomato & lettuce',
    price: 35.00,
    image: 'https://client.boilerroom.online/hades/19.%20cheese%2C%20tomato%2C%20and%20lettuce%20fresh%20roll.png',
    description: 'Fresh cheese, tomato, crisp lettuce',
    options: { chips: true }
  },
  {
    id: 'roll-ham',
    category: 'Fresh Rolls',
    name: 'Ham, Cheese, Tom & lettuce',
    price: 40.00,
    image: 'https://client.boilerroom.online/hades/20.%20ham%2C%20cheese%2C%20tomato%20and%20lettuce%20fresh%20roll.png',
    description: 'Ham, cheese, tomato, fresh lettuce',
    options: { chips: true }
  },
  {
    id: 'roll-chicken',
    category: 'Fresh Rolls',
    name: 'Chicken Mayo & Avo',
    price: 46.00,
    image: 'https://client.boilerroom.online/hades/21.%20chicken%20mayonnaise%20and%20avo%20fresh%20roll.png',
    description: 'Chicken mayo, fresh avocado',
    options: { chips: true }
  },
  {
    id: 'roll-bacon',
    category: 'Fresh Rolls',
    name: 'Bacon, cheese & Avo',
    price: 49.00,
    image: 'https://client.boilerroom.online/hades/22.%20bacon%2C%20cheese%20and%20avo%20fresh%20roll.png',
    description: 'Crispy bacon, cheese, fresh avocado',
    options: { chips: true }
  },
  {
    id: 'roll-chip',
    category: 'Fresh Rolls',
    name: 'Chip Roll',
    price: 35.00,
    image: 'https://client.boilerroom.online/hades/23.%20new%20chip%20roll.jpg',
    description: 'Fresh roll filled with golden crispy chips'
  },
  {
    id: 'roll-vienna',
    category: 'Fresh Rolls',
    name: 'Vienna Chip Roll',
    price: 50.00,
    image: 'https://client.boilerroom.online/hades/24.%20new%20vienna%20chip%20roll.jpg',
    description: 'Vienna sausage with golden crispy chips'
  },
  {
    id: 'roll-russian',
    category: 'Fresh Rolls',
    name: 'Russian Chip Roll',
    price: 60.00,
    image: 'https://client.boilerroom.online/hades/25.%20new%20russian%20chip%20roll.jpg',
    description: 'Russian sausage with golden crispy chips'
  },
  {
    id: 'side-chips',
    category: 'Sides',
    name: 'Portion of Chips',
    price: 20.00,
    image: 'https://client.boilerroom.online/hades/26.%20sides%20chips.png',
    description: 'Golden, crispy potato chips'
  },
  {
    id: 'drinks',
    category: 'Drinks & Snacks',
    name: 'Cold Drinks',
    image: 'https://client.boilerroom.online/hades/27.%20drinks.png',
    description: 'Insert drink',
    options: { drinkType: true }
  },
  {
    id: 'snacks',
    category: 'Drinks & Snacks',
    name: 'Snacks',
    image: 'https://client.boilerroom.online/hades/28.%20snacks.jpg',
    description: 'Chips, Chocolate, or Peanuts',
    options: { snackType: true }
  }
] as const;