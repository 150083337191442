import React from 'react';
import { CartItem, OrderDetails } from '../types';
import { ShoppingCart, Trash2, X } from 'lucide-react';

interface CartProps {
  items: CartItem[];
  isOpen: boolean;
  onClose: () => void;
  onRemove: (id: string) => void;
  onCheckout: (details: OrderDetails) => void;
  deliveryOption: 'pickup' | 'delivery' | 'sitdown';
  setDeliveryOption: (option: 'pickup' | 'delivery' | 'sitdown') => void;
}

export default function Cart({
  items,
  isOpen,
  onClose,
  onRemove,
  onCheckout,
  deliveryOption,
  setDeliveryOption,
}: CartProps) {
  const total = items.reduce((sum, item) => {
    let itemTotal = item.price * item.quantity;
    if (item.addChips) itemTotal += 20;
    if (item.addFeta) itemTotal += 8;
    return sum + itemTotal;
  }, 0);

  const handleCheckout = () => {
    const orderText = `Hi Ardele | Nono,\n\nI would like to place an order for:\n\n${items
      .map((item) => {
        let text = `${item.quantity}x ${item.name}`;
        
        // Only add bread choice and toastie/sandwich type for Toasties & Sandwiches category
        if (item.category === 'Toasties & Sandwiches') {
          if (item.breadChoice) text += ` (${item.breadChoice})`;
          if (item.toastieType) text += ` (${item.toastieType})`;
        }
        
        // Add other options for all items where applicable
        if (item.hotdogSize === 'double') text += ` (double)`;
        if (item.addChips) text += ' + chips';
        if (item.addFeta) text += ' + feta';
        if (item.drinkType) text += ` (${item.drinkType})`;
        if (item.snackType) text += ` (${item.snackType})`;
        return text;
      })
      .join('\n')}\n\n${deliveryOption.toUpperCase()}\n\nTotal: R${total.toFixed(2)}`;

    const whatsappUrl = `https://wa.me/27662661968?text=${encodeURIComponent(orderText)}`;
    window.open(whatsappUrl, '_blank');
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
      <div className="fixed inset-y-0 right-0 max-w-md w-full bg-white shadow-xl flex flex-col">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-semibold flex items-center gap-2">
            <ShoppingCart /> Cart
          </h2>
          <button onClick={onClose} className="p-2">
            <X />
          </button>
        </div>

        <div className="flex-1 overflow-y-auto p-4">
          {items.length === 0 ? (
            <p className="text-center text-gray-500 mt-8">Your cart is empty</p>
          ) : (
            <div className="space-y-4">
              {items.map((item, index) => (
                <div key={`${item.id}-${index}`} className="flex justify-between items-center bg-gray-50 p-3 rounded-lg">
                  <div>
                    <h3 className="font-medium">
                      {item.name}
                      {item.hotdogSize === 'double' && ` (double)`}
                    </h3>
                    <p className="text-sm text-gray-600">
                      R {item.price.toFixed(2)} x {item.quantity}
                    </p>
                    {item.category === 'Toasties & Sandwiches' && (
                      <>
                        {item.breadChoice && (
                          <p className="text-sm text-gray-600">{item.breadChoice} bread</p>
                        )}
                        {item.toastieType && (
                          <p className="text-sm text-gray-600">{item.toastieType}</p>
                        )}
                      </>
                    )}
                    {item.addChips && (
                      <p className="text-sm text-gray-600">+ chips (R20)</p>
                    )}
                    {item.addFeta && (
                      <p className="text-sm text-gray-600">+ feta (R8)</p>
                    )}
                    {item.drinkType && (
                      <p className="text-sm text-gray-600">{item.drinkType}</p>
                    )}
                    {item.snackType && (
                      <p className="text-sm text-gray-600">{item.snackType}</p>
                    )}
                  </div>
                  <button
                    onClick={() => onRemove(item.id)}
                    className="text-red-600 p-2"
                  >
                    <Trash2 size={18} />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="border-t p-4 space-y-4">
          <div className="flex justify-between items-center">
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Order Type
              </label>
              <div className="flex flex-wrap gap-2">
                <button
                  className={`px-4 py-2 rounded-full text-sm ${
                    deliveryOption === 'pickup'
                      ? 'bg-red-600 text-white'
                      : 'bg-gray-100'
                  }`}
                  onClick={() => setDeliveryOption('pickup')}
                >
                  Pickup
                </button>
                <button
                  className={`px-4 py-2 rounded-full text-sm ${
                    deliveryOption === 'delivery'
                      ? 'bg-red-600 text-white'
                      : 'bg-gray-100'
                  }`}
                  onClick={() => setDeliveryOption('delivery')}
                >
                  Delivery
                </button>
                <button
                  className={`px-4 py-2 rounded-full text-sm ${
                    deliveryOption === 'sitdown'
                      ? 'bg-red-600 text-white'
                      : 'bg-gray-100'
                  }`}
                  onClick={() => setDeliveryOption('sitdown')}
                >
                  Sit Down
                </button>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center font-semibold text-lg">
            <span>Total:</span>
            <span>R {total.toFixed(2)}</span>
          </div>

          <button
            onClick={handleCheckout}
            disabled={items.length === 0}
            className="w-full bg-green-600 text-white py-3 rounded-lg font-semibold disabled:opacity-50"
          >
            Order on WhatsApp
          </button>
        </div>
      </div>
    </div>
  );
}